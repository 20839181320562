.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-default {
  background: #fff !important;
}

.ant-btn > span {
  line-height: 1;
}

.ant-btn:not(.ant-btn-primary):not(.ant-btn-text) {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
}

.ant-btn:not(.ant-btn-primary) .ant-btn-icon svg path {
  stroke: #333d49;
}

.ant-btn:not(:disabled):not(.ant-btn-disabled):not(.ant-btn-primary):hover {
  color: #333d49 !important;
  background: #f7f7f7 !important;
}

.ant-btn:not(:disabled):not(.ant-btn-disabled):not(.ant-btn-primary):not(
    .ant-btn-text
  ):hover {
  border-color: #e4e8e9;
}

.ant-btn:not(:disabled):not(.ant-btn-disabled):not(.ant-btn-primary):focus {
  background: #00467a0d !important;
  border-color: #002748 !important;
}

.ant-btn:not(:disabled):not(.ant-btn-disabled):not(.ant-btn-primary):active {
  background: #fff;
}

.ant-btn.ant-btn-text:not(:disabled):not(.ant-btn-disabled):focus {
  border-color: #fff0 !important;
}

.ant-btn.ant-btn-text:not(:disabled):not(.ant-btn-disabled):active {
  border-color: #002748 !important;
}

.ant-btn-primary {
  background-color: #002748 !important;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #1a3d5a !important;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
  background-color: #001121 !important;
}

.ant-btn:not(.ant-btn-icon-only) > .ant-btn-icon:not(:last-child) {
  margin-inline-end: 4px;
}

.ant-btn.ant-btn-lg.btn-only-text {
  padding: 4px 12px;
}

.ant-btn.btn-only-text {
  padding: 4px 9px;
}

.ant-btn.ant-btn-lg .ant-btn-icon svg {
  width: 20px;
  height: auto;
}

.ant-btn .ant-btn-icon svg {
  width: 18px;
  height: auto;
}

.ant-btn.ant-btn-sm .ant-btn-icon svg {
  width: 16px;
  height: auto;
}

.ant-btn-icon-only.ant-btn-lg .ant-btn-icon svg {
  width: 24px;
  height: auto;
}

.ant-btn:disabled .ant-btn-icon svg path,
.ant-btn.ant-btn-disabled .ant-btn-icon svg path {
  stroke: #a7b3b9 !important;
}

/* Btn Success */
.ant-btn.ant-btn-success .ant-btn-icon svg path {
  stroke: #fff;
}

.ant-btn.ant-btn-success:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff !important;
  background: #3ca574 !important;
  border-color: #3ca574 !important;
}

.ant-btn.ant-btn-success:not(:disabled):not(.ant-btn-disabled):focus {
  background: #3ca574 !important;
  border-color: #3ca574 !important;
}

.ant-btn.ant-btn-success:not(:disabled):not(.ant-btn-disabled):active {
  background: #097345 !important;
  border-color: #097345 !important;
}

.ant-btn-success:not(:disabled):not(.ant-btn-disabled) {
  background-color: #0b8e51 !important;
  color: #fff;
}

/* Btn Danger */
.ant-btn.ant-btn-dangerous .ant-btn-icon svg path {
  stroke: #fff;
}

.ant-btn.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff !important;
  background: #f01b46 !important;
  border-color: #f01b46 !important;
}

.ant-btn.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):focus {
  background: #f01b46 !important;
  border-color: #f01b46 !important;
}

.ant-btn.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):active {
  background: #d5002a !important;
  border-color: #d5002a !important;
}

.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled) {
  background-color: #ee0231 !important;
  color: #fff;
}

.ant-form-item .ant-form-item-label > label {
  font-size: 16px;
  font-weight: 500;
  color: #0d0f11;
}

.ant-form-item .ant-form-item-extra {
  font-size: 16px;
  color: #525e68;
  margin-top: 8px;
}

.ant-pagination .ant-pagination-item {
  border-radius: 8px;
  font-weight: 500;
}

.ant-pagination .ant-pagination-item-active {
  background-color: #002748;
}

.ant-pagination .ant-pagination-item-active a {
  color: #fff;
}

.ant-slider-track {
  background-color: #007fdf !important;
}

.ant-heart path {
  stroke: #007fdf;
}

.ant-heart-solid path {
  fill: #007fdf;
}

.ant-slider-rail {
  border-radius: 8px !important;
}

.ant-slider-handle::after {
  background-color: #007fdf !important;
  box-shadow: 0 0 0 8px #ffff !important;
}

.ant-slider .ant-slider-handle:focus::before {
  border: 15px solid #007fdf !important;
  border-radius: 100% !important;
  margin: -5px !important;
}

.ant-slider-disabled .ant-slider-handle::after {
  background: #cbd3d6 !important;
  color: #e4e8e9;
}

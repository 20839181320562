@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font */
@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-Book.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: url("../fonts/PPNeueMontreal-Light.ttf");
  font-weight: 300;
}

/* Components */
@import url(./components/alert.css);
@import url(./components/avatar.css);
@import url(./components/breadcrumb.css);
@import url(./components/checkbox.css);
@import url(./components/collapse.css);
@import url(./components/date-picker.css);
@import url(./components/dropdown.css);
@import url(./components/form.css);
@import url(./components/input-number.css);
@import url(./components/input.css);
@import url(./components/menu.css);
@import url(./components/message.css);
@import url(./components/modal.css);
@import url(./components/pagination.css);
@import url(./components/progress.css);
@import url(./components/radio.css);
@import url(./components/select.css);
@import url(./components/slider.css);
@import url(./components/steps.css);
@import url(./components/tabs.css);
@import url(./components/tag.css);
@import url(./components/tel-input.css);
@import url(./components/time-picker.css);
@import url(./components/tooltip.css);
@import url(./components/upload.css);
@import url(./components/button.css);

/* Base */
input::-ms-input-placeholder {
  color: #7b8b95 !important;
}

input::placeholder {
  color: #7b8b95 !important;
}

.ant-modal .ant-modal-close:hover{
  background-color: white!important;
  box-shadow: none!important;
}

.ant-modal-root .ant-modal-mask {
  background: rgba(158, 158, 158, 0.40);
}

.page-container {
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
}

/* Slider Icons */
.icon-wrapper {
  position: relative;
  padding: 0px 30px;
}

.icon-wrapper .anticon {
  position: absolute;
  top: -2px;
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 1;
}

.icon-wrapper .icon-wrapper-active {
  color: rgba(0, 0, 0, 0.45);
}

.icon-wrapper .anticon:first-child {
  left: 0;
}

.icon-wrapper .anticon:last-child {
  right: 0;
}

/* Slider Dots */
.slick-dots {
  position: static !important;
  margin-top: 16px !important;
}

.slick-dots li {
  height: 8px;
  width: 35px !important;
}

.slick-dots li button {
  height: 8px !important;
  border-radius: 8px !important;
  background: #e4e8e9 !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button {
  background: #002748 !important;
}

/* Header */
.header-category-dropdown:not(.header-default-dropdown) .ant-dropdown {
  width: calc(100% - 450px) !important;
}

.header-default-dropdown .ant-dropdown {
  width: 330px !important;
}

.header-category-dropdown:not(.header-default-dropdown)
  .ant-dropdown
  .ant-dropdown-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-top: 15px;
}

.header-profile-dropdown .ant-dropdown {
  width: 350px !important;
}

.header-profile-dropdown .ant-dropdown .ant-dropdown-menu-item:first-child {
  pointer-events: none;
  padding-bottom: 8px !important;
}

.header-profile-dropdown .ant-dropdown .ant-dropdown-menu-item:last-child {
  background: none !important;
}

.header-profile-dropdown .ant-dropdown .ant-dropdown-menu-item {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
  display: block;
}

@media (max-width: 1199px) {
  .header-category-dropdown:not(.header-default-dropdown) .ant-dropdown {
    width: calc(100% - 310px) !important;
  }
}

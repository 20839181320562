.ant-tabs-card.ant-tabs .ant-tabs-tab-btn {
  color: #525e68;
  font-weight: 500;
}

.ant-tabs-card.ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #007fdf;
}

.ant-tabs-card.ant-tabs .ant-tabs-tab-active {
  background: #f4f6f7 !important;
}

.ant-tabs-card.ant-tabs .ant-tabs-tab {
  background: none;
  border: none;
  padding: 6px 16px;
  border-radius: 8px !important;
}

.ant-tabs-card .ant-tabs-nav:before {
  opacity: 0;
}

.default-tab.ant-tabs .ant-tabs-tab {
  padding-left: 4px;
  padding-right: 4px;
}

.default-tab.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 16px;
}
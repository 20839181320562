.allow-dropdown.intl-tel-input {
  width: 100%;
}

.intl-tel-input input,
.intl-tel-input input[type="text"],
.intl-tel-input input[type="tel"] {
  box-sizing: border-box;
  margin: 0;
  padding: 5px 16px;
  color: #002748;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e4e8e9;
  border-radius: 8px;
  transition: all 0.2s;
  padding: 6px 16px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
}

.intl-tel-input input:focus,
.intl-tel-input input[type="text"]:focus,
.intl-tel-input input[type="tel"]:focus {
  border-color: #002748;
  box-shadow: 0 0 0 2px rgba(4, 21, 29, 0.53);
  outline: 0;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"] {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  margin-left: 0.5rem !important;
}

.intl-tel-input.allow-dropdown .selected-flag {
  position: relative !important;
  width: 4rem !important;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e4e8e9;
  border-radius: 8px;
  transition: all 0.2s;
}

.allow-dropdown.intl-tel-input {
  display: flex;
}

.intl-tel-input .flag-container {
  position: relative !important;
  width: auto;
}

.intl-tel-input.allow-dropdown .flag-container {
  width: auto !important;
}

.intl-tel-input .flag-container .arrow.down:after {
  position: absolute !important;
  content: "" !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNhcmV0RG93biI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xNi42OTIgNy45NDIxN0wxMC40NDIgMTQuMTkyMkMxMC4zODQgMTQuMjUwMyAxMC4zMTUxIDE0LjI5NjQgMTAuMjM5MiAxNC4zMjc4QzEwLjE2MzMgMTQuMzU5MyAxMC4wODIgMTQuMzc1NSA5Ljk5OTg2IDE0LjM3NTVDOS45MTc3MiAxNC4zNzU1IDkuODM2NCAxNC4zNTkzIDkuNzYwNTIgMTQuMzI3OEM5LjY4NDY1IDE0LjI5NjQgOS42MTU3MiAxNC4yNTAzIDkuNTU3NjcgMTQuMTkyMkwzLjMwNzY3IDcuOTQyMTdDMy4xOTA0IDcuODI0ODkgMy4xMjQ1MSA3LjY2NTgzIDMuMTI0NTEgNy40OTk5OEMzLjEyNDUxIDcuMzM0MTMgMy4xOTA0IDcuMTc1MDcgMy4zMDc2NyA3LjA1Nzc5QzMuNDI0OTUgNi45NDA1MiAzLjU4NDAxIDYuODc0NjMgMy43NDk4NiA2Ljg3NDYzQzMuOTE1NzEgNi44NzQ2MyA0LjA3NDc3IDYuOTQwNTIgNC4xOTIwNSA3LjA1Nzc5TDkuOTk5ODYgMTIuODY2NEwxNS44MDc3IDcuMDU3NzlDMTUuODY1NyA2Ljk5OTcyIDE1LjkzNDcgNi45NTM2NiAxNi4wMTA1IDYuOTIyMjRDMTYuMDg2NCA2Ljg5MDgxIDE2LjE2NzcgNi44NzQ2MyAxNi4yNDk5IDYuODc0NjNDMTYuMzMyIDYuODc0NjMgMTYuNDEzMyA2Ljg5MDgxIDE2LjQ4OTIgNi45MjIyNEMxNi41NjUgNi45NTM2NiAxNi42MzQgNi45OTk3MiAxNi42OTIgNy4wNTc3OUMxNi43NTAxIDcuMTE1ODYgMTYuNzk2MiA3LjE4NDggMTYuODI3NiA3LjI2MDY3QzE2Ljg1OSA3LjMzNjU0IDE2Ljg3NTIgNy40MTc4NiAxNi44NzUyIDcuNDk5OThDMTYuODc1MiA3LjU4MjEgMTYuODU5IDcuNjYzNDIgMTYuODI3NiA3LjczOTI5QzE2Ljc5NjIgNy44MTUxNiAxNi43NTAxIDcuODg0MSAxNi42OTIgNy45NDIxN1oiIGZpbGw9IiMzMzNENDkiLz4KPC9nPgo8L3N2Zz4K);
  background-size: 100% 100%;
  z-index: 99 !important;
  right: 0.5rem;
  width: 16px !important;
  height: 16px !important;
  top: 50%;
  transform: translateY(-50%);
}

.iti-flag {
  position: absolute;
  left: 0.5rem;
}

.default-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 16px !important;
}

.default-collapse.ant-collapse > .ant-collapse-item {
  border-radius: 8px !important;
  overflow: hidden;
  background: #fff;
}

.default-collapse.ant-collapse.default-collapse-noradius > .ant-collapse-item {
  border-radius: 0 !important;
  border: none !important;
}

.ant-collapse > .ant-collapse-item {
  border-radius: 16px !important;
  border: 1px solid #e4e8e9 !important;
}

.ant-collapse > .ant-collapse-item + .ant-collapse-item {
  margin-top: 16px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background: #f4f6f7;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover i,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover span {
  color: #007fdf;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover svg path {
  stroke: #007fdf;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item svg path,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item i,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item span {
  transition: all 0.2s;
}

.ant-dropdown .ant-dropdown-menu {
  border-radius: 8px;
  border: 1px solid #e4e8e9;
  background: #fff;
  box-shadow: 0px 24px 52px 0px rgba(148, 148, 148, 0.1),
    0px 94px 94px 0px rgba(148, 148, 148, 0.09),
    0px 212px 127px 0px rgba(148, 148, 148, 0.05),
    0px 378px 151px 0px rgba(148, 148, 148, 0.01),
    0px 590px 165px 0px rgba(148, 148, 148, 0);
  padding: 8px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  border-radius: 8px;
  padding: 12px;
}

.select-noborder .ant-select-selector {
  border: none !important;
}

.ant-select-single {
  border-radius: 8px;
  height: 40px;
  font-size: 16px;
  background-color: #fff;
}

.ant-select:not(.select-noborder):not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #e4e8e9;
}

.ant-select-single .ant-select-selection-item {
  font-size: 16px;
}

.ant-select-selector {
  padding: 0 16px !important;
}

.ant-select .ant-select-arrow {
  color: #000;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #f4f6f7 !important;
  color: #0d0f11 !important;
  font-weight: 500 !important;
}

.ant-select-dropdown {
  border-radius: 8px;
  background: var(--primary-white, #fff);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(103, 110, 118, 0.16),
    0px 2px 5px 0px rgba(103, 110, 118, 0.08) !important;
}

.ant-select-dropdown {
  border-radius: 12px;
  border: 1px solid #e4e8e9 !important;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.07) !important;
  padding: 14px;
}

.ant-select-dropdown .ant-select-item-option {
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 4px 0;
}

.ant-alert-with-description {
  align-items: center;
}

.ant-alert-with-description .ant-alert-icon {
  margin-right: 16px !important;
}

.ant-alert-message {
  font-weight: 500;
  margin-bottom: 4px !important;
}

.ant-checkbox .ant-checkbox-inner {
  background-color: #fff !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #002748 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #cbd3d6 !important;
}

.ant-checkbox .ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
  border-radius: 6px !important;
}

.ant-checkbox .ant-checkbox-inner::after {
  left: 50% !important;
  translate: -60% !important;
  width: 7px;
  height: 12px;
  margin-left: -1px;
  border-width: 3px;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: #fff;
}

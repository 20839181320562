.ant-input.active,
.ant-input-affix-wrapper.active {
  border-color: #002748;
}

.ant-input-clear-icon.ant-input-clear-icon-has-suffix
  .anticon.anticon-close-circle
  svg
  path {
  fill: #0d0f11;
}

.ant-input-affix-wrapper-status-success,
.ant-input-number-status-success {
  border: 1px solid #0b8e51 !important;
}

.ant-input-affix-wrapper-status-success:focus-within,
.ant-input-number-status-success:focus-within {
  box-shadow: 0px 0px 0px 3px rgba(11, 142, 81, 0.2) !important;
}

.ant-input-affix-wrapper-status-error:focus-within {
  box-shadow: 0px 0px 0px 3px rgba(238, 2, 49, 0.2) !important;
}

input:read-only {
  background: #e4e8e9 !important;
}

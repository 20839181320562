.ant-menu-submenu-popup.ant-menu-submenu .ant-menu {
  border: 1px solid #e4e8e9;
  padding: 4px 0;
}

.ant-menu-submenu-title:active {
  background-color: transparent !important;
}

.ant-menu-title-content:active {
  background-color: transparent !important;
}

.ant-menu .ant-menu-item:active {
  background-color: transparent !important;
}
.ant-menu-item.ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item.ant-menu-item-selected:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

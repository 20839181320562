.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
  width: 34px;
}

.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .anticon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMTQ4MyA0LjY2ODFMNi45NTM4OCAwLjQ3MzY5MUM2LjMyMjI5IC0wLjE1Nzg5OSA1LjMwMjAzIC0wLjE1Nzg5OSA0LjY3MDQ0IDAuNDczNjkxTDAuNDc2MDMxIDQuNjY4MUMtMC41NDQyMyA1LjY4ODM2IDAuMTg0NTI4IDcuNDM3MzggMS42MjU4NSA3LjQzNzM4TDEwLjAxNDcgNy40MzczOEMxMS40NTYgNy40MzczOCAxMi4xNjg1IDUuNjg4MzYgMTEuMTQ4MyA0LjY2ODFaIiBmaWxsPSIjOTY5Njk2Ii8+Cjwvc3ZnPgo=");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-bottom: -5px;
}

.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .anticon
  svg {
  opacity: 0;
}

.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler-down
  .anticon {
  transform: rotate(180deg);
  margin-right: 0px;
  margin-bottom: 0;
  margin-top: -5px;
}

.ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
  border: none;
  height: 40% !important;
}

.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler:hover
  .anticon {
  opacity: 0.8;
}

.ant-input-number-disabled .ant-input-number-handler-wrap,
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: flex;
  background-color: #e4e8e9;
}

.ant-upload-wrapper .ant-upload-drag {
  background: #fff;
  border-radius: 16px;
  border: 2px dashed #e4e8e9;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
  color: #333d49;
  font-size: 14px;
  font-weight: 500;
}

.ant-upload-wrapper .ant-upload-drag-hover {
  background: #eef8ff;
  border-color: #007fdf;
}

.ant-upload-wrapper
  .ant-upload-drag-hover
  .ant-upload-drag-container
  .ant-upload-text {
  color: #007fdf;
}

.ant-upload-wrapper
  .ant-upload-drag-hover
  .ant-upload-drag-container
  .ant-upload-drag-icon
  svg
  path {
  stroke: #007fdf;
}

.ant-radio-wrapper .ant-radio-inner {
  border-color: #e4e8e9;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
  width: 24px;
  height: 24px;
}

.ant-radio-wrapper:active .ant-radio-inner {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07),
    0px 0px 0px 2px rgba(0, 39, 72, 0.2);
}

.ant-radio-wrapper .ant-radio-inner::after {
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: -12px;
}

.ant-radio-wrapper
  .ant-radio-disabled.ant-radio-checked
  .ant-radio-inner::after {
  transform: scale(0.4);
}

.ant-radio-wrapper .ant-radio-disabled.ant-radio-checked::after {
  border-color: #d9d9d9 !important;
}

.radio-group .ant-radio-wrapper > span {
  font-size: 18px;
  color: #525e68;
  font-weight: 500;
}

.radio-group .ant-radio-wrapper-checked > span {
  color: #000000;
}

.radio-group .ant-radio-wrapper > span:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
